import { Alert, Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

interface Props {
    disabled: boolean;
    error: string;
    onChangeSaveEnabledState?: any;
    onOptimaUsernameInput?: any;
    onOptimaPasswordInput?: any;
  }
  
  export const ConfirmOptimaCredentials = ({
    disabled,
    error,
    onChangeSaveEnabledState,
    onOptimaUsernameInput,
    onOptimaPasswordInput
  }: Props) => {

    const [isDisabeled, setIsDisabled] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);

    const [showError, setShowError] = useState((error ?? "") !== "");
    const [errorMessage, setErrorMessage] = useState(error ?? "");
    
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
  

    useEffect(() => {
      
      setIsDisabled(disabled);

    }, [disabled]);


    useEffect(() => {

      if((error ?? "") !== errorMessage) {
        setErrorMessage(error!);
        setShowError(error! !== "");
      }

    }, [error]);


     useEffect(() => {
      
      onOptimaUsernameInput(username);
      onOptimaPasswordInput(password);
  
    }, [username, password, onOptimaUsernameInput, onOptimaPasswordInput]);


    useEffect(() => {

      onChangeSaveEnabledState(saveEnabled);
  
    }, [saveEnabled, onChangeSaveEnabledState]);
  

    const handleUsernameInput = (username: string) => {
      setUsername(username);
      onValidateUsernameAndPassword(username, password);
    }


    const handlePasswordInput = (password: string) => {
      setPassword(password);
      onValidateUsernameAndPassword(username, password);
    }


    const onValidateUsernameAndPassword = (username: string, password: string) => {

      if((username !== undefined && username.length > 0) && (password !== undefined && password.length > 0)) {
        setSaveEnabled(true);
      } else {
        setSaveEnabled(false);
      }
    }


    return (
      <>        
        <Box padding="12px">
          <Typography variant="body1" color="#606060" > Please enter your Optima Credentials to continue with the update</Typography>
        </Box>
      <Box padding="12px" marginBottom="4px" minWidth="350px">
        <TextField
          fullWidth
          size="small"
          label="Username"
          margin="dense"
          required={true}
          disabled={isDisabeled}
          onChange={(event) => handleUsernameInput(event.target.value)}
          error={!username}
        ></TextField>
      </Box>
      <Box padding="12px" paddingTop="4px" marginBottom="8px" minWidth="350px">
        <TextField
          fullWidth
          type="password"
          size="small"
          label="Password"
          margin="dense"
          required={true}
          disabled={isDisabeled}
          onChange={(event) => handlePasswordInput(event.target.value)}
          error={!password}
        ></TextField>
      </Box>

      <Box padding="12px" marginBottom="8px">
        {showError && <Alert severity="error">{errorMessage}</Alert>}
      </Box>
        </>
    )
  }