import bindActions from "../../../actions";
import { useGlobalStore } from "../../../store";
import emailReducer from "../../../store/userInfo/updateEmail";

const { actions } = emailReducer;

/**
 * useEmailUpdate Custom Hook
 */
const useUpdateEmail: any = () => {
	const { state , dispatch } = useGlobalStore();

	// List of Props
	const { updateEmail } = state;

	// List of Actions
	const {
		handleUpdateEmail 
	} = actions;

	// Bind Actions
	const updateEmailActions = bindActions({
		handleUpdateEmail 
	}, dispatch);

	return { updateEmail, ...updateEmailActions };
}

export default useUpdateEmail;
