import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    CancelToken,
} from "axios";
import { ApiException } from "../domain/src/api/apiException";
import { CommandResult, ICommandResult } from "../domain/src/api/commandResult";
import { QueryResult } from "../domain/src/api/queryResult";
import { globalConfig } from "../configuration/config";

export abstract class axiosClient {
    private instance: AxiosInstance;
    private baseUrl: string;
    private config = globalConfig.get(); 
    constructor() {
        this.instance = axios.create();
        this.baseUrl = this.config.ApiUrl;
    }

    public Authorize(token: string): AxiosRequestConfig {
        let options: AxiosRequestConfig;
        options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json"
            },
        };
        return options;
    }

    public NonAuthorize(): AxiosRequestConfig {
        let options: AxiosRequestConfig;
        options = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "text/plain",
            },
        };

        return options;
    }

    protected async get(
        action: string,
        options: AxiosRequestConfig,
        cancelToken?: CancelToken | undefined
    ): Promise<QueryResult> {
        let url = this.baseUrl + action;
        url = url.replace(/[?&]$/, "");

        return this.instance
            .get<AxiosResponse>(url, options)
            .catch((_error: any) => {
                if (isAxiosError(_error) && _error.response) {
                    return _error.response;
                } else {
                    throw _error;
                }
            })
            .then((_response: AxiosResponse) => {
                return this.processQueryResult(_response);
            });
    }

    protected async post<T>(
        action: string,
        data: T,
        options: AxiosRequestConfig
    ): Promise<ICommandResult> {
        let url = this.baseUrl + action;
        url = url.replace(/[?&]$/, "");

        return this.instance
            .post<Promise<ICommandResult>>(url, JSON.stringify(data), options)
            .catch((_error: any) => {
                if (isAxiosError(_error) && _error.response) {
                    return _error.response;
                } else {
                    throw _error;
                }
            })
            .then((_response: AxiosResponse) => {
                return this.processCommandResult(_response);
            });
    }

    protected async put<T>(
        action: string,
        data: T,
        options: AxiosRequestConfig
    ): Promise<ICommandResult> {
        let url = this.baseUrl + action;
        url = url.replace(/[?&]$/, "");

        return this.instance
            .put<Promise<ICommandResult>>(url, JSON.stringify(data), options)
            .catch((_error: any) => {
                if (isAxiosError(_error) && _error.response) {
                    return _error.response;
                } else {
                    throw _error;
                }
            })
            .then((_response: AxiosResponse) => {
                return this.processCommandResult(_response);
            });
    }

    protected processQueryResult(response: AxiosResponse): Promise<QueryResult> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;          
            return _responseText;
        } else if (status === 400) {
            const _responseText = response.data;
            let result400: any = null;
            let resultData400 = _responseText;
            result400 = QueryResult.fromJS(resultData400);
            return throwException(
                "Bad Request",
                status,
                _responseText,
                _headers,
                result400
            );
        } else if (status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<QueryResult>(<any>null);
    }
    protected processCommandResult(
        response: AxiosResponse
    ): Promise<ICommandResult> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            result200 = CommandResult.fromJS(resultData200);
            return result200;
        } else if (status === 400) {
            const _responseText = response.data;
            let result400: any = null;
            let resultData400 = _responseText;
            result400 = CommandResult.fromJS(resultData400);
            return throwException(
                "Bad Request",
                status,
                _responseText,
                _headers,
                result400
            );
        } else if (status !== 204) {
            const _responseText = response.data;
            return throwException(
                "An unexpected server error occurred.",
                status,
                _responseText,
                _headers
            );
        }
        return Promise.resolve<CommandResult>(<any>null);
    }
}
function throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any
): any {
    if (result !== null && result !== undefined) throw result;
    else throw new ApiException(message, status, response, headers, null);
}

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}
