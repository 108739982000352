import { Box, DialogActions, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TitleBar } from "../../components/generic-components/TitleBar";
import { SecondaryButtonDesktop } from "../../components/secondary-button/SecondaryButtonDesktop";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

interface Props {
  onClose: () => void;
  onSave: (fName: string, lName: string) => void;
  apiErrorMessage: string;
  isLoading: boolean;
}

export const UpdateGeneralInformation = ({
  onClose,
  onSave,
  apiErrorMessage,
  isLoading,
}: Props) => {
  const { user, getIdTokenClaims, getAccessTokenSilently } = useAuth0();

  const storedFirstName = localStorage.getItem("firstName");
  const storedLastName = localStorage.getItem("lastName");
  const [firstName, setFirstName] = useState(storedFirstName);
  const [lastName, setLastName] = useState(storedLastName);
  const [email, setEmail] = useState(user?.email);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(apiErrorMessage);
  const [isDisabled, setIsDisabled] = useState(false);
  const [connection, setConnection] = useState("");

  useEffect(() => {
    if (apiErrorMessage != "") {
      setErrorMessage(apiErrorMessage);
      setShowError(true);
      apiErrorMessage = "";
    }
  }, [apiErrorMessage]);

  useEffect(() => {
    //apiErrorMessage = "";
    if (firstName && lastName && !saveEnabled) {
      setSaveEnabled(true);
      setShowError(false);
    } else if (!firstName || !lastName) {
      setSaveEnabled(false);
      setErrorMessage((!firstName ? "First" : "Last") + " name is required.");
      setShowError(true);
    }
    if (firstName && firstName.length > 150) {
      setSaveEnabled(false);
      setErrorMessage("First name should contain Maximum 150 characters.");
      setShowError(true);
    }
    if (lastName && lastName.length > 150) {
      setSaveEnabled(false);
      setErrorMessage("Last name should contain Maximum 150 characters.");
      setShowError(true);
    }
  }, [firstName, lastName]);

  useEffect(() => {
    let isMounted = true;

    (async function GetTokenClaims() {
      var claims = await getIdTokenClaims();

      if (claims === undefined) {
        var token = await getAccessTokenSilently();
        claims = await getIdTokenClaims();
      }

      if (isMounted) {
        setConnection(claims?.sub.split("|")[0]);
      }
    })();

    if (connection === "adfs") {
      setIsDisabled(true);
    }

    return () => {
      isMounted = false;
    };
  }, [connection, getIdTokenClaims]);

  return (
    <>
      <TitleBar title="First and Last Name"></TitleBar>
      <div>
        {isDisabled ? (
          <Box padding="12px">
            <Typography variant="body2" fontFamily="inter" marginTop="8px">
              Information managed by your organization{" "}
            </Typography>
          </Box>
        ) : null}
      </div>
      <Box padding="12px">
        <TextField
          fullWidth
          size="small"
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
          label="First Name"
          margin="dense"
          disabled={isDisabled}
          multiline
        ></TextField>
      </Box>

      <Box padding="12px" minWidth="350px">
        <TextField
          fullWidth
          size="small"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
          label="Last Name"
          margin="dense"
          disabled={isDisabled}
          multiline
        ></TextField>
      </Box>

      <Box padding="12px" marginBottom="8px">
        {showError && <Alert severity="error">{errorMessage}</Alert>}
      </Box>

      <div className="DrawerFooter">
        <DialogActions>
          <SecondaryButtonDesktop onClick={onClose} buttonLabel="Cancel" />
          {!isDisabled || !saveEnabled ? (
            <LoadingButton
              onClick={() => onSave(firstName ?? "", lastName ?? "")}
              disabled={!saveEnabled}
              loading={isLoading}
              style={{ width: "100%", marginTop: "24px" }}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </LoadingButton>
          ) : null}
        </DialogActions>
      </div>
    </>
  );
};
