import App from "./App";
import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import Provider from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import {
  defaultConfig,
  globalConfig,
  globalConfigUrl,
} from "./configuration/config";
import { Auth0ProviderWithHistory } from "./auth0-provider-with-history";
import LocalizationParams from "./configuration/localization";

const app: ReactElement = <App />;
axios
  .get(globalConfigUrl)
  .then((response) => {
    globalConfig.set(response.data);
    return app;
  })
  .catch((e) => {
    if (process.env.NODE_ENV === "development") {
      globalConfig.set(defaultConfig);
      return app;
    } else {
      const errorMessage =
        "Error while fetching global config, the App wil not be rendered. (This is NOT a React error.)";
      return (
        <p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>
      );
    }
  })
  .then((reactElement: ReactElement) => {
    ReactDOM.render(
      <React.StrictMode>
        <Provider>
          <Router>
            <Auth0ProviderWithHistory>
              <App />
            </Auth0ProviderWithHistory>
          </Router>
        </Provider>
      </React.StrictMode>,
      document.getElementById("root")
    );

    const config = globalConfig.get();
    let region = config.Region || "UK";

    if (region === "US") {
      LocalizationParams.setLanguage("US");
    }
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
