import styled from "styled-components";
import { iconButtonClasses, MenuList } from "@mui/material";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { FC } from "react";
import { Link, NavLink } from "react-router-dom";

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #767676;
`;

export const StyledMenuList = styled(MenuList)`
  // margin left: 8px;
  // width: 216px;
  // display: flex
  // flex-direction: column;
  // min-height: 100vh;
`;

export interface Items {
  Icon?: any;
  label?: any;
  route?: string;
}

interface ItemsProps {
  items: Items[];
}

export const VerticalNavigation: FC<ItemsProps> = ({ items }) => {
  return (
    <>
      {items.map(({ Icon, label, route }, index: number) => (
        <StyledLink to={route ?? "/"} key={index}>
          <MenuItem sx={{ marginTop: "8px", marginBottom: "8px" }} key={index}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        </StyledLink>
      ))}
    </>
  );
};
