import AILogo from "../../assets/logo.svg";
import IconButton from "@mui/material/IconButton";
import Person from "@mui/icons-material/Person";
import styled from "styled-components";
import React from "react";
import AppBar from "@mui/material/AppBar";
import { Box } from "@mui/system";
import { Menu, MenuItem, Toolbar } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import authenticationServiceHook from "../../hooks/authenticationServiceHook";

export const AppLogo = styled.img`
  width: auto;
  height: auto;
  margin; auto;
  @media (max-width: 478px) {
    height: 24px;
  }
`;

export const Header = () => {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    localStorage.clear();
  };

  const { user, logout } = authenticationServiceHook();

  const storedFirstName = localStorage.getItem("firstName")
    ? localStorage.getItem("firstName")
    : user?.given_name;
  const storedLastName = localStorage.getItem("lastName")
    ? localStorage.getItem("lastName")
    : user?.family_name;

  return (
    <>
      <AppBar position="sticky" sx={{background: "#287e94"}}>
        <Toolbar
          className="toolbar"
          sx={{ justifyContent: "space-between", background: "#287e94" }}
        >
          <AppLogo alt="Allocate Identity Logo" src={AILogo} />
          <Box className="appbar-actions">
            <Box className="user-credentials" sx={{'@media (max-width: 478px)': {
              display: 'none',
            },}}>
            Welcome {storedFirstName} {storedLastName}
            </Box>
            <Box>
            <IconButton onClick={handleMenu} size="large">
              <Person
                sx={{
                  color: "#ffffff",
                }}
              ></Person>
            </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        sx={{ mt: "32px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Log Out</MenuItem>
      </Menu>
    </>
  );
};
