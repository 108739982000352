import { ICommandResult } from "../../../domain/src/api/commandResult";

//PHONE FLOW
export const START_PASSWORDLESS_FLOW = "START_PASSWORDLESS_FLOW";
export const START_PASSWORDLESS_FLOW_FAIL = "START_PASSWORDLESS_FLOW_FAIL";
export interface GetPasswordlessFlowState {
  result: ICommandResult;
}

export interface GetPasswordlessFlowType {
  type: typeof START_PASSWORDLESS_FLOW | typeof START_PASSWORDLESS_FLOW_FAIL;
  payload: ICommandResult;
}
export type PasswordlessFlowType = GetPasswordlessFlowType;
