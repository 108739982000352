export class EmailCommand implements IEmailCommand {
    email?: string;
    optimaUsername?: string;
    optimaPassword?: string;

    constructor(data?: IEmailCommand) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (this as any)[property] = (data as any)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.email = _data["email"];
            this.optimaUsername = _data["optimaUsername"];
            this.optimaPassword = _data["optimaPassword"];
        }
    }

    static fromJS(data: any): EmailCommand {
        data = typeof data === 'object' ? data : {};
        let result = new EmailCommand();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["email"] =  this.email;
        data["optimaUsername"] =  this.optimaUsername;
        data["optimaPassword"] =  this.optimaPassword;
        return data; 
    }
}

export interface IEmailCommand {
    email?: string;
    optimaUsername?: string;
    optimaPassword?: string;
}