export class CommandResult implements ICommandResult {
    isValid?: boolean;
    message?: string | undefined;

    constructor(data?: ICommandResult) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (this as any)[property] = (data as any)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.isValid = _data["isValid"] || _data["IsValid"];
            let msg = _data["message"]  || _data["Message"];
            this.message = msg?.replace(/["\[\]\\]/gi, '');  //ignore [ ] \ characthers if exist in the message
        }
    }

    static fromJS(data: any): CommandResult {
        data = typeof data === 'object' ? data : {};
        let result = new CommandResult();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["isValid"] = this.isValid;
        data["message"] = this.message?.replace(/["\[\]\\]/gi, ''); //ignore [ ] \ characthers if exist in the message
        return data; 
    }
}

export interface ICommandResult {
    isValid?: boolean;
    message?: string | undefined;
}
 