import React, { useCallback } from "react";
import { Edit, EmailTwoTone } from "@mui/icons-material";
import { MainSection } from "../../components/main-section/MainSection";
import InputField from "../general-information/GeneralInformationPage";
import { Button, TextField } from "@mui/material";
import { ScreenLayout } from "../../components/screen-layout/ScreenLayout";
import { GenericDrawer } from "../../components/generic-components/GenericDrawer";
import { useAuth0 } from "@auth0/auth0-react";
import { UpdateEmailSteps } from "./steps/UpdateEmailSteps";

export const EmailPage = () => {
  const { user, getAccessTokenSilently } = useAuth0();

  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState(user?.email);

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmpty = () => {
    setOpen(false);
  };
   
  return (
    <>
      <ScreenLayout>
        <MainSection
          PageIcon={EmailTwoTone}
          pageTitle="Email"
          pageDescription="Update your email"
        >
          <InputField>
            <TextField
              required
              id="email"
              label="Email"
              variant="standard"
              size="medium"
              fullWidth
              defaultValue={email}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
            />
          </InputField>
          <div className="IconButton">
            <Button
              color="primary"
              variant="contained"
              fullWidth
              startIcon={<Edit />}
              onClick={() => setOpen(true)}
            >
              Update
            </Button>
          </div>
        </MainSection>
        <GenericDrawer
          open={open}
          onClose={handleClose}
          drawerName="Update Email"
          onSave={handleEmpty}
        >
          <UpdateEmailSteps onClose={handleClose} ></UpdateEmailSteps>
        </GenericDrawer>
      </ScreenLayout>
    </>
  );
};

