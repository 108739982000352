import { Typography } from "@mui/material";
import React, { FC } from "react";

interface TitleBarProps {
    title?:string;
}

export const TitleBar: FC<TitleBarProps> = ({
title,
}) => {

    return(
        <div className="TitleBar">
            <Typography color="#404040" variant="body1" fontWeight="600" fontFamily="inter">
                {title}
            </Typography>
        </div>
    )
}