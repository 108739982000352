import styled from "styled-components";
import Button from "@mui/material/Button";
import React, { FC } from "react";

const StyledPrimaryButton = styled.button`
  display: flex;
  justify-content: center;
  height: auto;
  margin-top: 24px;
  width: 100%;
  border: none;
  @media (max-width: 478px) {
    & > .MuiButtonBase-root {
      height: 44px;
      width: 100%;
      font-size: 16px;
    }
  }
`;

interface Props {
  children?: any;
  primaryButtonLabel?: any;
  onClick?: () => void;
  disabled?: boolean;
}

export const PrimaryButtonDesktop: FC<Props> = ({
  children,
  primaryButtonLabel,
  onClick,
  disabled,
}) => {
  return (
    <StyledPrimaryButton>
      <Button
        onClick={onClick}
        variant="contained"
        size="medium"
        disableElevation
        fullWidth
        disabled={disabled}
      >
        {primaryButtonLabel}
      </Button>
    </StyledPrimaryButton>
  );
};
