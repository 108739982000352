import { ICommandResult } from "../../../domain/src/api/commandResult";

//VERIFY CODE
export const VERIFY_CODE = "VERIFY_CODE";
export const VERIFY_CODE_FAIL = "VERIFY_CODE_FAIL";
export interface SetVerifyCodeState {
  result: ICommandResult;  
}

export interface SetVerifyCodeType {
  type: typeof VERIFY_CODE | typeof VERIFY_CODE_FAIL;
  payload: ICommandResult;    
}
export type VerifyCodeType = SetVerifyCodeType;

