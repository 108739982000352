import { useGlobalStore } from '../../store';
import bindActions from '../../actions';
import firstNameLastNameReducer from '../../store/userInfo/firstNameLastName';

const { actions } = firstNameLastNameReducer;

/**
 * useAssotiation Custom Hook 
 */
const useFirstNameLastName: any = () => {
	const { state , dispatch } = useGlobalStore();

	// List of Props
	const { firstNameLastName, updateFirstNameLastName  } = state;

	// List of Actions
	const {
		handleUpdateFirstNameAndLastName
	} = actions;

	// Bind Actions
	const firstNameLastNameActions = bindActions({
		handleUpdateFirstNameAndLastName
	}, dispatch);

	return { firstNameLastName, updateFirstNameLastName , ...firstNameLastNameActions };
}

export default useFirstNameLastName;
