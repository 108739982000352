import { ICommandResult } from "../../../domain/src/api/commandResult";

export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_EMAIL_FAIL = "UPDATE_EMAIL_FAIL";

export interface UpdateEmailState {
  result: ICommandResult;
}

export interface UpdateEmailType {
  type: typeof UPDATE_EMAIL | typeof UPDATE_EMAIL_FAIL;
  payload: ICommandResult;
}
export type EmailType = UpdateEmailType;