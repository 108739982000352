import { useAuth0 } from "@auth0/auth0-react";
import { authenticatedUser } from "../domain/authenticatedUser";
import { getDataFromLocalStorage } from "./getDataFromLocalStorage";

const auth0spajs = "@@auth0spajs@@";

const User = function () {
  const { user } = useAuth0();

  var userInfoFromLocalStorage = getDataFromLocalStorage(auth0spajs, true).body
    ?.decodedToken?.user;

  if (!localStorage.getItem("phoneNumber") && user?.phone_number != undefined) {
    localStorage.setItem("phoneNumber", user?.phone_number);
  }

  if (!localStorage.getItem("firstName") && user?.given_name != undefined) {
    localStorage.setItem("firstName", user?.given_name);
  }

  if (!localStorage.getItem("lastName") && user?.family_name != undefined) {
    localStorage.setItem("lastName", user?.family_name);
  }

  const authenticationUser = new authenticatedUser();

  authenticationUser.name = user?.name;
  authenticationUser.given_name = user?.given_name;
  authenticationUser.family_name = user?.family_name;
  authenticationUser.middle_name = user?.middle_name;
  authenticationUser.nickname = user?.nickname;
  authenticationUser.preferred_username = user?.preferred_username;
  authenticationUser.profile = user?.profile;
  authenticationUser.picture = user?.picture;
  authenticationUser.website = user?.website;
  authenticationUser.email = user?.email;
  authenticationUser.email_verified = user?.email_verified;
  authenticationUser.gender = user?.gender;
  authenticationUser.birthdate = user?.birthdate;
  authenticationUser.zoneinfo = user?.zoneinfo;
  authenticationUser.locale = user?.locale;
  authenticationUser.phone_number =
    userInfoFromLocalStorage !== undefined
      ? userInfoFromLocalStorage[
          "http://schemas.allocatesoftware.com/v1/identity/claims/phoneNumber"
        ]
      : "";
  authenticationUser.phone_number_verified = user?.phone_number_verified;
  authenticationUser.address = user?.address;
  authenticationUser.updated_at = user?.updated_at;
  authenticationUser.sub = user?.sub;
  authenticationUser.trusts =
    userInfoFromLocalStorage !== undefined
      ? userInfoFromLocalStorage[
          "http://schemas.allocatesoftware.com/v1/identity/claims/trusts"
        ]
      : [];
  authenticationUser.primary_trust =
    userInfoFromLocalStorage !== undefined
      ? userInfoFromLocalStorage[
          "http://schemas.allocatesoftware.com/v1/identity/claims/primary_trust"
        ]
      : [];
  authenticationUser.roles =
    userInfoFromLocalStorage !== undefined
      ? userInfoFromLocalStorage[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ]
      : [];

  return authenticationUser;
};

export { User as UserFromAuthenticationService };

function GetAccessToken() {
  const { getAccessTokenSilently } = useAuth0();

  var accessTokenLocalStorage = getDataFromLocalStorage(auth0spajs, true).body
    .access_token;

  return accessTokenLocalStorage
    ? accessTokenLocalStorage
    : getAccessTokenSilently;
}

export { GetAccessToken as getAccessTokenFromAuthenticationService };

function GetIdToken() {
  const { getIdTokenClaims } = useAuth0();

  var idTokenLocalStorage = getDataFromLocalStorage("token", false);

  return idTokenLocalStorage ? idTokenLocalStorage : getIdTokenClaims;
}

export { GetIdToken as getIdTokenFromAuthenticationService };

function GetRefreshToken() {
  var refreshTokenLocalStorage = getDataFromLocalStorage(auth0spajs, true).body
    .refresh_token;

  return refreshTokenLocalStorage ? refreshTokenLocalStorage : "";
}

export { GetRefreshToken as getRefreshTokenFromAuthenticationService };

function GetTokenExpiration() {
  var tokenExpirationLocalStorage = getDataFromLocalStorage(auth0spajs, true)
    .expiresAt;

  return tokenExpirationLocalStorage ? tokenExpirationLocalStorage : "";
}

export { GetTokenExpiration as getTokenExpirationFromAuthenticationService };

function GetAuth0State() {
  var auth0StateLocalStorage = getDataFromLocalStorage("auth0state", false);

  return auth0StateLocalStorage ? auth0StateLocalStorage : "";
}

export { GetAuth0State as getAuth0StateFromAuthenticationService };

function Error() {
  const { error } = useAuth0();

  return error;
}

export { Error as errorFromAuthenticationService };

function IsAuthenticated() {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated;
}

export { IsAuthenticated as isAuthenticatedFromAuthenticationService };

function IsLoading() {
  const { isLoading } = useAuth0();

  return isLoading;
}

export { IsLoading as isLoadingFromAuthenticationService };

function LoginWithRedirect() {
  const { loginWithRedirect } = useAuth0();

  return loginWithRedirect;
}

export { LoginWithRedirect as loginWithRedirectFromAuthenticationService };

function LoginWithPopup() {
  const { loginWithPopup } = useAuth0();

  return loginWithPopup;
}

export { LoginWithPopup as loginWithPopupFromAuthenticationService };

function Logout() {
  const { logout } = useAuth0();

  return logout;
}

export { Logout as logoutFromAuthenticationService };
