import { useGlobalStore } from '../../../store';
import bindActions from '../../../actions';
import verifyCodeReducer from '../../../store/userInfo/verifyUpdatePhoneNumberCode';

const { actions } = verifyCodeReducer;

/**
 * useVerifyCode Custom Hook
 */
const useVerifyCode: any = () => {
	const { state , dispatch } = useGlobalStore();

	// List of Props
	const { verifyCode } = state;

	// List of Actions
	const {
		handleVerifyCode 
	} = actions;

	// Bind Actions
	const verifyCodeActions = bindActions({
		handleVerifyCode 
	}, dispatch);

	return { verifyCode, ...verifyCodeActions };
}

export default useVerifyCode;
