import { ICommandResult } from "../../domain/src/api/commandResult";
import { initialState } from "../../reducers";
import { userInfo } from "../../api/userInfo";
import { IFirstNameAndLastNameCommand } from "../../commands/userInfo/firstNameLastName";
import {
  UPDATE_FIRST_NAME_AND_LAST_NAME,
  UPDATE_FIRST_NAME_AND_LAST_NAME_FAIL,
  FirstNameAndLastNameType
} from "../../domain/userInfo/firstNameLastName";

// in actions folder
export const updateFirstNameAndLastNameAction = ( result: ICommandResult ): FirstNameAndLastNameType => {
	return {
	  type: UPDATE_FIRST_NAME_AND_LAST_NAME,
	  payload: result,
	};
};
  
  export const updateFirstNameAndLastNameFailAction = ( err: ICommandResult): FirstNameAndLastNameType => {
	return {
	  type: UPDATE_FIRST_NAME_AND_LAST_NAME_FAIL,
	  payload: err,
	};
};

export const handleUpdateFirstNameAndLastName = (token: string, command: IFirstNameAndLastNameCommand) => {
  return function (dispatch: any) {
    const endpoint = new userInfo();
    endpoint.updateCustomerFirstNameAndLastName(token, command)
      .then((data) => {
        if (!data.isValid) {
          dispatch(updateFirstNameAndLastNameFailAction(initialState.firstNameLastName.result));
          return data;
        }
        dispatch(updateFirstNameAndLastNameAction(data));
        return data;
      })
      .catch((err) => {
        dispatch(updateFirstNameAndLastNameFailAction(err));
      });
  };
};