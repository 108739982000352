import { CommandResult } from "../../../domain/src/api/commandResult";
import {
  START_PASSWORDLESS_FLOW,
  GetPasswordlessFlowState,
  START_PASSWORDLESS_FLOW_FAIL,
} from "../../../domain/userInfo/updatePhoneFlow";

export const initialState: GetPasswordlessFlowState = {
  result: new CommandResult({ isValid: false }),
};

export default function phoneFlow(state = initialState, action: any) {
  switch (action.type) {
    case START_PASSWORDLESS_FLOW:
      return {
        ...state,
        result: action.payload,
      };
      case START_PASSWORDLESS_FLOW_FAIL:
        return { ...state, result: action.payload };
    default:
      return state;
  }
};
