import React, { useCallback, useEffect, useRef } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import countries from "countries-list";
import { TitleBar } from "../../../components/generic-components/TitleBar";
import { useState } from "react";
import { SecondaryButtonDesktop } from "../../../components/secondary-button/SecondaryButtonDesktop";
import { PrimaryButtonDesktop } from "../../../components/primary-button/PrimaryButtonDesktop";
import { OneTimePassword } from "./OneTimePassword";
import { PasswordlessSMSFlowCommand } from "../../../domain/src/api/command";
import authenticationServiceHook from "../../../hooks/authenticationServiceHook";
import { isValidPhoneNumber } from "react-phone-number-input";
import useUpdatePhoneFlow from "../../../hooks/useUserInfo/useUpdatePhoneFlow";
import useVerifyCode from "../../../hooks/useUserInfo/useVerifyUpdatePhoneNumberCode";

const codes = Object.entries(countries.countries).map(([code, country]) => {
  return {
    code: "+" + country.phone + " " + country.name,
    shortName: code,
  };
});

interface Props {
  onClose: () => void;
}

export const NewPhoneNumber = ({ onClose }: Props) => {
  const { accessToken, user } = authenticationServiceHook();
  const [renderItemIndex, setRenderItemIndex] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const [showPhoneNumber, setshowPhoneNumber] = useState(true);
  const [showErrorCode, setShowErrorCode] = useState(false);
  const [showErrorPhone, setShowErrorPhone] = useState(user.email_verified);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const { updatePhoneFlow, handleUpdatePhoneNumberFlow } = useUpdatePhoneFlow();
  const { verifyCode, handleVerifyCode } = useVerifyCode();
  const codeRef = useRef<string>("");
  const phoneRef = useRef<string>("");
  const areaCodeRef = useRef<string>("");

  const handleNext = () => {
    setshowPhoneNumber(false);
    const phoneNumberAndCode =
      areaCodeRef.current.split(" ")[0] + phoneRef.current;
    if (isValidPhoneNumber(phoneNumberAndCode)) {
      startPasswordlessMethod(accessToken, phoneNumberAndCode);
      setShowErrorPhone(false);
    }
    setRenderItemIndex(1);
  };

  const handleBack = () => {
    setshowPhoneNumber(true);
    setShowErrorCode(false);
    setRenderItemIndex(0);
  };

  const handleCode = (otpCode: string) => {
    codeRef.current = otpCode;
    setShowErrorCode(false);
    setIsValid(true);
  };

  const verifyCodeMethod: any = useCallback(
    async (token: string, code: string, phone: string) =>
      await handleVerifyCode(token, code, phone),
    [handleVerifyCode]
  );

  const handleSave = async () => {
    const phoneNumberAndCode =
      areaCodeRef.current.split(" ")[0] + phoneRef.current;
    if (codeRef != undefined || codeRef != null) {
      await verifyCodeMethod(
        accessToken,
        codeRef.current,
        phoneNumberAndCode
      ).then(() => {
        setIsCodeValid(true);
      });
    }
  };

  useEffect(() => {
    if (isCodeValid) {
      if (
        verifyCode.result &&
        (verifyCode.result.isValid == false || verifyCode.result.message)
      ) {
        setIsCodeValid(false);
        setShowErrorCode(true);
        setIsValid(false);
      } else {
        const phoneNumberAndCode =
          areaCodeRef.current.split(" ")[0] + phoneRef.current;
        localStorage.setItem("phoneNumber", phoneNumberAndCode);
        onClose();
      }
    }
  }, [verifyCode]);

  const handlePhoneNumberChange = (event: any) => {
    phoneRef.current = event.target.value;
    setIsValid(false);
    validatePhoneNumber();
  };

  const handleAreaCodeChange = (event: any, values: any) => {
    areaCodeRef.current = values.code;
    setIsValid(false);
    validatePhoneNumber();
  };

  const validatePhoneNumber = () => {
    const phoneNumberAndCode =
      areaCodeRef.current.split(" ")[0] + phoneRef.current;
    if (isValidPhoneNumber(phoneNumberAndCode) && user.email_verified) {
      setShowErrorPhone(false);
      setIsValid(true);
    } else if (phoneRef.current !== "") {
      setShowErrorPhone(true);
      setIsValid(false);
    }
  };

  const startPasswordlessMethod: any = useCallback(
    (token: string, phone: string) =>
      handleUpdatePhoneNumberFlow(
        token,
        new PasswordlessSMSFlowCommand({ phoneNumber: phone })
      ),
    [handleUpdatePhoneNumberFlow]
  );

  const list = [
    {
      id: 0,
      Component: NewPhoneNumber,
      primaryButton: {
        title: "Next",
        action: handleNext,
      },
      secondaryButton: {
        title: "Cancel",
        action: onClose,
      },
    },

    {
      id: 1,
      Component: OneTimePassword,
      primaryButton: {
        title: "Save",
        action: handleSave,
      },

      secondaryButton: {
        title: "Back",
        action: handleBack,
      },
    },
  ];

  const RenderItem = React.useMemo(
    () => list.find((i) => i.id === renderItemIndex),
    [renderItemIndex]
  );

  return (
    <>
      {showPhoneNumber ? (
        <>
          <TitleBar title="New Phone Number"></TitleBar>
          <Box padding="12px">
            <Typography variant="body2" color="#606060">
              Update your phone number by choosing the area code and then enter
              your new phone number.
            </Typography>
          </Box>
          <Box padding="12px">
            <Autocomplete
              id="auto-complete"
              autoComplete
              includeInputInList
              options={codes}
              getOptionLabel={(option: any) => option.code}
              onChange={handleAreaCodeChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Area Code"
                  variant="outlined"
                  required
                  size="small"
                />
              )}
            />
          </Box>

          <Box padding="12px" marginBottom="32px">
            <TextField
              fullWidth
              size="small"
              label="Phone Number"
              margin="dense"
              required
              onChange={handlePhoneNumberChange}
            ></TextField>
          </Box>
        </>
      ) : (
        <OneTimePassword
          code={handleCode}
          phoneNumber={areaCodeRef.current.split(" ")[0] + phoneRef.current}
        ></OneTimePassword>
      )}
      <Box>
        {showErrorCode && (
          <Alert severity="error">
            {verifyCode.result.message
              ? verifyCode.result.message
              : "Your verification code is incorrect! Enter again or start over."}
          </Alert>
        )}
        {showErrorPhone && (
          <Alert severity="error">
            {user.email_verified
              ? "Your phone number is incorrect!"
              : "Please verify your email address before you try to update you phone number."}
          </Alert>
        )}
        <DialogActions>
          <SecondaryButtonDesktop
            buttonLabel={RenderItem!.secondaryButton.title}
            onClick={RenderItem!.secondaryButton.action}
          ></SecondaryButtonDesktop>
          <PrimaryButtonDesktop
            primaryButtonLabel={RenderItem!.primaryButton.title}
            onClick={RenderItem!.primaryButton.action}
            disabled={!isValid}
          ></PrimaryButtonDesktop>
        </DialogActions>
      </Box>
    </>
  );
};
