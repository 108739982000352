import { useAuth0 } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material";
import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import { QueryProvider } from "./context/query";
import theme from "./styles/theme";
import { PageLoader } from "./components/page-loader";
import { ProtectedRoute } from "./components/protected-route";
import { EmailPage } from "./screens/email/EmailPage";
import { GeneralInformationPage } from "./screens/general-information/GeneralInformationPage";
import { MyAccountsPage } from "./screens/my-accounts/MyAccountsPage";
import { MyOrganisationPage } from "./screens/my-organisation/MyOrganisationPage";
import { OverviewPage } from "./screens/overview/OverviewPage";
import { PasswordPage } from "./screens/password/PasswordPage";
import { PhoneNumberPage } from "./screens/phone-number/PhoneNumberPage";

const App: React.FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <QueryProvider>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Switch>
            <ProtectedRoute path="/" exact component={GeneralInformationPage} />
            {/* <ProtectedRoute
              exact
              path="/my-organisations"
              component={MyOrganisationPage}
            /> */}
            <ProtectedRoute
              exact
              path="/general-information"
              component={GeneralInformationPage}
            />
            <ProtectedRoute
              exact
              path="/phone-number"
              component={PhoneNumberPage}
            />
            <ProtectedRoute exact path="/email" component={EmailPage} />
            {/* <ProtectedRoute exact path="/password" component={PasswordPage} />
            <ProtectedRoute
              exact
              path="/my-accounts"
              component={MyAccountsPage}
            />
            <ProtectedRoute exact path="/overview" component={OverviewPage} /> */}
          </Switch>
        </div>
      </ThemeProvider>
    </QueryProvider>
  );
};
export default App;
