import LocalizedStrings from 'react-localization';

const LocalizationParams = new LocalizedStrings({
    DEFAULT:{
        TRUST_LABEL:"Organisation"
        },
    US:{
        TRUST_LABEL:"Organization"
        }
  });

export default LocalizationParams;
  