import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import { padding } from "@mui/system";
import React from "react";

export const SliderCloseButton = () => {
  return (
    <CancelIcon
      sx={{
        border: "1px solid white",
        borderRadius: "50px",
        background: "white",
      }}
      fontSize="medium"
    ></CancelIcon>
  );
};
