import { CommandResult } from "../../domain/src/api/commandResult";
import {
    UpdateFirstNameAndLastNameState,
    UPDATE_FIRST_NAME_AND_LAST_NAME, UPDATE_FIRST_NAME_AND_LAST_NAME_FAIL
} from "../../domain/userInfo/firstNameLastName";

export const initialState: UpdateFirstNameAndLastNameState = {
    result: new CommandResult({ isValid: false }),
  };
  
export default function firstNameAndLastName(state = initialState, action: any) {
    switch (action.type) {
      case UPDATE_FIRST_NAME_AND_LAST_NAME:
        return {
          ...state,
          result: action.payload,
        };
        case UPDATE_FIRST_NAME_AND_LAST_NAME_FAIL:
          return { ...state, result: action.payload };
      default:
        return state;
    }
};