import { useGlobalStore } from '../../../store';
import bindActions from '../../../actions';
import phoneFlowReducer from '../../../store/userInfo/updatePhoneFlow';

const { actions } = phoneFlowReducer;

/**
 * useUpdatePhoneFlow Custom Hook
 */
const useUpdatePhoneFlow: any = () => {
	const { state , dispatch } = useGlobalStore();

	// List of Props
	const { updatePhoneFlow } = state;

	// List of Actions
	const {
		handleUpdatePhoneNumberFlow 
	} = actions;

	// Bind Actions
	const updatePhoneFlowActions = bindActions({
		handleUpdatePhoneNumberFlow 
	}, dispatch);

	return { updatePhoneFlow, ...updatePhoneFlowActions };
}

export default useUpdatePhoneFlow;
