import {
	START_PASSWORDLESS_FLOW,
	START_PASSWORDLESS_FLOW_FAIL,
	PasswordlessFlowType,
  } from "../../../domain/userInfo/updatePhoneFlow/index";
import {  passwordlessClient } from "../../../api/updatePhoneNumberClient";
import { ICommandResult } from "../../../domain/src/api/commandResult";
import { IPasswordlessSMSFlowCommand } from "../../../domain/src/api/command";

  // in actions folder
  export const startUpdatePhoneNumberFlowAction = (
	result: ICommandResult
  ): PasswordlessFlowType => {
	return {
	  type: START_PASSWORDLESS_FLOW,
	  payload: result,
	};
  };
  
  export const startUpdatePhoneNumberFailAction = (
	err: ICommandResult
  ): PasswordlessFlowType => {
	return {
	  type: START_PASSWORDLESS_FLOW_FAIL,
	  payload: err,
	};
  };

  export const handleUpdatePhoneNumberFlow = (token: string, command: IPasswordlessSMSFlowCommand) => {
	return function (dispatch: any) {
	  const endpoint = new passwordlessClient();
	  endpoint
		.startPasswordlessFlow(token, command)
		.then((data) => {
		  dispatch(startUpdatePhoneNumberFlowAction(data));
		  return data;
		})
		.catch((err) => {
		  dispatch(startUpdatePhoneNumberFailAction(err));
		});
	};
  };
 