import styled from "styled-components";
import { Container, Typography } from "@mui/material";
import React, { FC } from "react";

const PageContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding-top: 48px;

  @media (max-width: 478px) {
    justify-content: center;
    margin-top: 0px;
    margin-left: 0px;
  }
`;

export const PageIcon = styled.img`
width:auto;
height:48px;
@media(max-width:478px){
    display:none;
    align-items:center;
    justify-content:center;
    flex-grow:0;
    

`;

interface Props {
  children?: any;
  PageIcon?: any;
  pageTitle?: any;
  pageDescription?: any;
}

export const MainSection: FC<Props> = ({
  children,
  PageIcon,
  pageTitle,
  pageDescription,
}) => {
  return (
    <>
      <PageContent>
        {PageIcon && <PageIcon sx={{ color: "#23A1C2", fontSize: "48px" }} />}
        <Typography sx={{ margin: "4px" }} variant="h5">
          {pageTitle}
        </Typography>
        <Typography sx={{ padding: "4px" }} variant="body2" color="#606060">
          {pageDescription}
        </Typography>
        <Container maxWidth="xs">{children}</Container>
      </PageContent>
    </>
  );
};
