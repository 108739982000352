import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FC, useState } from "react";
import React from "react";
import { StyledLink } from "../vertical-navigation/VerticalNavigation";
import { Items } from "../vertical-navigation/VerticalNavigation";

interface Props {
  items?: any;
  route?: string;
}

export const TabNavigation: FC<Props> = ({ items, route }) => {

  const currentRoute = window.location.pathname;
  let index = items.findIndex((x: Items) => x.route === currentRoute)

  if (index === -1){
    index = 0;
  }

  const [value, setValue] = useState(index);

  return (
    <Tabs
      value={value}
      aria-label="tab-navigation"
      variant="scrollable"
      scrollButtons="auto"
    >
      {items.map((item: any) => (
        <StyledLink to={item?.route ?? "/"} key={item.label}>
          <Tab label={item.label} />
        </StyledLink>
      ))}
    </Tabs>
  );
};
