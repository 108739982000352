import { AppState, Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { useHistory } from "react-router-dom";
import { globalConfig } from "./configuration/config";

interface Auth0ProviderWithConfigProps {
  children: React.ReactNode;
}

export const Auth0ProviderWithHistory = ({
  children,
}: PropsWithChildren<Auth0ProviderWithConfigProps>): JSX.Element | null => {
  const history = useHistory();

  const domain = globalConfig.config.Domain;
  const clientId = globalConfig.config.ClientId;
  const redirectUri = window.location.origin;
  const defaultScope = "openid profile email healthsuite";
  const defaultAudience = "https://identityv2.allocate-cloud.co.uk/";

  const onRedirectCallback = (appState?: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId)) {
    const errorMessage =
      "Error missing auth0 configuration. (This is NOT a React error.)";
    return <p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>;
  }

  return (
    <Auth0Provider
      domain={globalConfig.config.Domain}
      clientId={globalConfig.config.ClientId}
      scope={
        globalConfig.config.Scope ? globalConfig.config.Scope : defaultScope
      }
      audience={
        globalConfig.config.Audience
          ? globalConfig.config.Audience
          : defaultAudience
      }
      useRefreshTokens={true}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
