import firstNameLastNameStore from '../store/userInfo/firstNameLastName';
import verifyCodeStore from '../store/userInfo/verifyUpdatePhoneNumberCode';
import updatePhoneFlowStore from '../store/userInfo/updatePhoneFlow'
import updateEmailStore from '../store/userInfo/updateEmail';

import { logger } from '../store/middlewares';
import { UpdateFirstNameAndLastNameState } from '../domain/userInfo/firstNameLastName';
import { SetVerifyCodeState } from '../domain/userInfo/verifyUpdatePhoneNumberCode';
import { GetPasswordlessFlowState } from '../domain/userInfo/updatePhoneFlow';
import { UpdateEmailState } from '../domain/userInfo/updateEmail';

export interface IState {
	firstNameLastName: UpdateFirstNameAndLastNameState;
	verifyCode: SetVerifyCodeState;
	updatePhoneFlow: GetPasswordlessFlowState;
	updateEmail: UpdateEmailState;
}

export const initialState: IState = {
	firstNameLastName: firstNameLastNameStore.initialState,
	verifyCode: verifyCodeStore.initialState,
	updatePhoneFlow: updatePhoneFlowStore.initialState,
	updateEmail: updateEmailStore.initialState
}

export default function mainReducer(state: IState, action: object) {
	// Receiving previous state here
	const { firstNameLastName, verifyCode, updatePhoneFlow, updateEmail } = state;

	// Receiving current state here
	const currentState = {
		firstNameLastName: firstNameLastNameStore.reducer(firstNameLastName, action),
		verifyCode: verifyCodeStore.reducer(verifyCode, action),
		updatePhoneFlow: updatePhoneFlowStore.reducer(updatePhoneFlow, action),
		updateEmail: updateEmailStore.reducer(updateEmail, action)
	};

	// Middlewares
	logger(action, state, currentState);

	return currentState;
}
