import { Drawer, Box, Typography, IconButton } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { SliderCloseButton } from "./SliderCloseButton";

interface Props {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  drawerName?: string;
  onSave: () => void;
}

export const GenericDrawer: FC<Props> = ({
  children,
  open,
  onClose,
  drawerName,
  onSave,
}) => (
  <>
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box maxWidth="478px"> 
        <div className="HeaderStripe"></div>
        <div className="DrawerHeader">
          <Typography
            color="#606060"
            fontFamily="inter"
            fontWeight="600"
            paddingLeft="16px"
            margin="8px"
            variant="body1"
          >
            {drawerName}
          </Typography>
          <IconButton onClick={onClose}>
            <SliderCloseButton />
          </IconButton>
        </div>
        {children}
      </Box>
    </Drawer>
  </>
);
