import styled from "styled-components";
import { TabNavigation } from "../tab-navigation/TabNavigation";
import { VerticalNavigation } from "../vertical-navigation/VerticalNavigation";
import {
  CorporateFareOutlined,
  Email,
  EmailOutlined,
  PersonAddOutlined,
  PersonOutlined,
  PhoneIphoneOutlined,
  VpnKeyOutlined,
} from "@mui/icons-material";
import React from "react";
import DashboardOutlined from "@mui/icons-material/DashboardOutlined";

const navigationItems = [
  // {
  //   Icon: DashboardOutlined,
  //   label: "Overview",
  //   route: "/overview",
  // },

  {
    Icon: PersonOutlined,
    label: "General Information",
    route: "/general-information",
  },

  {
    Icon: EmailOutlined,
    label: "Email",
    route: "/email",
  },
  {
    Icon: PhoneIphoneOutlined,
    label: "Phone Number",
    route: "/phone-number",
  },
  // {
  //   Icon: VpnKeyOutlined,
  //   label: "Password",
  //   route: "/password",
  // },
  // {
  //   Icon: CorporateFareOutlined,
  //   label: "My Organisations",
  //   route: "/my-organisations",
  // },
  // {
  //   Icon: PersonAddOutlined,
  //   label: "My Accounts",
  //   route: "/my-accounts",
  // },
];

const TabNavContainer = styled.div`
  overflow: auto;
`;

const VerticalNavContainer = styled.div`
  border-right: solid 3px #e5e5e5;
  min-height: 100vh;
`;
const NavContainer = styled.nav`
  display: flex;
  }
 
  
  ${VerticalNavContainer}{

  }
${TabNavContainer}{
    display: none;
    height:44px;
}
}
  @media(max-width: 430px){
      ${VerticalNavContainer}{
          display: none;
         
      }
      ${TabNavContainer}{
          display: block;
          height:60px;
          
      }
    }
  `;

export default class Navigation extends React.Component {
  render() {
    return (
      <NavContainer>
        <TabNavContainer>
          <TabNavigation items={navigationItems} />
        </TabNavContainer>
        <VerticalNavContainer>
          <VerticalNavigation items={navigationItems} />
        </VerticalNavContainer>
      </NavContainer>
    );
  }
}
