import React, { ReactNode } from "react";
import { Header } from "../header/Header";
import { MainSection } from "../main-section/MainSection";
import Navigation from "../navigation/Navigation";
import { Page } from "../page/Page";
import styled from "styled-components";
import { BreakPoints } from "../styled-constants/StyledConstants";

const Main = styled.main`
  display: flex;
  flex-wrap: nowrap;
  @media (max-width: 430px) {
    flex-direction: column;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const ScreenLayout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Header />
      <Main>
        <Navigation />
        <Section>{children}</Section>
      </Main>
    </>
  );
};
