import { ICommandResult } from "../../../domain/src/api/commandResult";
import { initialState } from "../../../reducers";
import { userInfo } from "../../../api/userInfo";
import { IEmailCommand } from "../../../commands/userInfo/email";
import { EmailType, UPDATE_EMAIL, UPDATE_EMAIL_FAIL } from "../../../domain/userInfo/updateEmail";

// in actions folder
export const updateEmailAction = ( result: ICommandResult ): EmailType => {
	return {
	  type: UPDATE_EMAIL,
	  payload: result,
	};
};
  
  export const updateEmailFailAction = ( err: ICommandResult): EmailType => {
	return {
	  type: UPDATE_EMAIL_FAIL,
	  payload: err,
	};
};

export const handleUpdateEmail = (token: string, command: IEmailCommand) => {

  return function (dispatch: any) {
    const endpoint = new userInfo();
    endpoint.updateCustomerEmail(token, command)
      .then((data) => {
        if (!data.isValid) {
          dispatch(updateEmailFailAction(initialState.updateEmail.result));
          return data;
        }
        dispatch(updateEmailAction(data));
        return data;
      })
      .catch((err) => {
        dispatch(updateEmailFailAction(err));
      });
      
  };
};