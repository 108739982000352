import { MainSection } from "../../components/main-section/MainSection";
import styled from "styled-components";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Edit, PersonTwoTone } from "@mui/icons-material";
import { TextField, Button, Typography, Box } from "@mui/material/";
import { ScreenLayout } from "../../components/screen-layout/ScreenLayout";
import { GenericDrawer } from "../../components/generic-components/GenericDrawer";
import { UpdateGeneralInformation } from "./UpdateGeneralInformation";
import { useAuth0 } from "@auth0/auth0-react";
import { globalConfig } from "../../configuration/config";
import useFirstNameLastName from "../../hooks/useUserInfo/firstNameLastName";
import { FirstNameAndLastNameCommand } from "../../commands/userInfo/firstNameLastName";

const InputField = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
`;
export default InputField;

export const GeneralInformationPage = () => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setApiErrorMessage("");
  };

  const { isLoading, user, getAccessTokenSilently } = useAuth0();
  const localStorageFirstName = localStorage.getItem("firstName")
    ? localStorage.getItem("firstName")
    : user?.given_name;
  const [storedFirstName, setStoredFirstName] = useState(localStorageFirstName);
  const localStorageLastName = localStorage.getItem("lastName")
    ? localStorage.getItem("lastName")
    : user?.family_name;
  const [storedLastName, setStoredLastName] = useState(localStorageLastName);
  const storedEmail = user?.email;
  const [loading, setLoading] = React.useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const {
    firstNameLastName,
    handleUpdateFirstNameAndLastName,
  } = useFirstNameLastName();

  const handleSave = async (firstName: string, lastName: string) => {
    setLoading(true);
    setApiErrorMessage("");
    if (firstName != undefined && lastName != undefined) {
      await updateUserInfo(firstName, lastName).then(() => {
        localStorage.setItem("updatedFirstName", firstName);
        localStorage.setItem("updatedLastName", lastName);
      });
    }
  };

  useEffect(() => {
    let firstName = localStorage.getItem("updatedFirstName");
    let lastName = localStorage.getItem("updatedLastName");
    if (firstName && lastName) {
      if (firstNameLastName.result && firstNameLastName.result.isValid) {
        localStorage.setItem("firstName", firstName);
        localStorage.setItem("lastName", lastName);
        setStoredFirstName(firstName);
        setStoredLastName(lastName);
        handleClose();
      } else {
        setApiErrorMessage(firstNameLastName.result.message);
      }
      setLoading(false);
    }
  }, [firstNameLastName]);

  const updateUserInfo: any = useCallback(
    async (firstName: string, lastName: string) => {
      var accessToken = await getAccessTokenSilently({
        scopesToInclude: globalConfig.config.Scope || "email",
      });
      await handleUpdateFirstNameAndLastName(
        accessToken,
        new FirstNameAndLastNameCommand({
          firstName: firstName,
          lastName: lastName,
        })
      );
    },
    []
  );
  return (
    <>
      <ScreenLayout>
        <MainSection
          PageIcon={PersonTwoTone}
          pageTitle="General Information"
          pageDescription="Update your general information"
        >
          <InputField>
            <TextField
              required
              id="first-name"
              label="First Name"
              variant="standard"
              size="medium"
              fullWidth
              value={storedFirstName}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
              multiline
            />
          </InputField>
          <InputField>
            <TextField
              required
              id="last-name"
              label="Last Name"
              variant="standard"
              size="medium"
              value={storedLastName}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
              multiline
            />
          </InputField>
          <div className="IconButton">
            <Button
              color="primary"
              variant="contained"
              fullWidth
              startIcon={<Edit />}
              onClick={() => setOpen(true)}
            >
              Update
            </Button>
          </div>
        </MainSection>
        <GenericDrawer
          open={open}
          onClose={handleClose}
          drawerName="Update General Information"
          onSave={handleClose}
        >
          <UpdateGeneralInformation
            onClose={handleClose}
            onSave={handleSave}
            apiErrorMessage={apiErrorMessage}
            isLoading={loading}
          ></UpdateGeneralInformation>
        </GenericDrawer>
      </ScreenLayout>
    </>
  );
};
