export const BreakPoints = {
    desktop: {
        width: '960px;'
    },

    mobile: {
        width: '500px;'
    }
}

export const ColorPalette = {
    identityPrimary:'#287e94',
    actionPrimary:'#005ecc',
    actionSecondary:'#5f5c87',
    backgroundColor: '#fff',
    semanticCritical:'#cf0e1e',
    semanticPositive:'#048a23',
    semanticOptimistic:'#d98d00',
    semanticEmpowered:'#026848',
    semanticWarning:'#b35715',
    semanticEnergetic:'#16b8d9',
    semanticRelaxed:'#653d93',
    semanticCalm:'#4bb26f',

    
}
