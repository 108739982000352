import { createTheme } from "@mui/material";
import { red, yellow } from "@mui/material/colors";
import { ColorPalette } from "../components/styled-constants/StyledConstants";
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    iconButton: true;
  }
}
// A custom theme for this app
const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },

  palette: {
    primary: {
      main: "#005ECC",
      contrastText: "#fff",
    },
    secondary: {
      main: "#0EAFCC",
      contrastText: "#fff",
    },
    success: {
      main: "#28a745",
      contrastText: "#fff",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: ColorPalette.backgroundColor,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "iconButton" },
          style: {
            backgroundColor: "#E5EFFA",
            color: "#005ECC",
            "&:hover": {
              backgroundColor: "#CED7E0",
              color: "#005ECC",
            },
            contrastText: "#ffffff",
          },
        },
      ],
      styleOverrides: {
        root: {
          backgroundColor: "#005ECC",
          "&:hover": {
            backgroundColor: "#0075FF",
          },
        },
      },
    },
  },
});

export default theme;
