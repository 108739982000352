export class QueryResult implements IQueryResult {
    isValid?: boolean;
    message?: string | undefined;

    constructor(data?: IQueryResult) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (this as any)[property] = (data as any)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.isValid = _data["isValid"] || _data["IsValid"];
            this.message = _data["message"] ||  _data["Message"];
        }
    }

    static fromJS(data: any): QueryResult {
        data = typeof data === 'object' ? data : {};
        let result = new QueryResult();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["isValid"] = this.isValid;
        data["message"] = this.message;
        return data; 
    }
}

export interface IQueryResult {
    isValid?: boolean;
    message?: string | undefined;
}
