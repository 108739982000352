import { axiosClient } from "../api/axiosClient";
import { ICommandResult } from "../domain/src/api/commandResult";
import { IFirstNameAndLastNameCommand } from "../commands/userInfo/firstNameLastName";
import { IEmailCommand } from "../commands/userInfo/email";

export class userInfo extends axiosClient {
  async updateCustomerFirstNameAndLastName(token: string, body: IFirstNameAndLastNameCommand): Promise<ICommandResult> {
    let action = "/api/v1/profile/update-first-name-and-last-name";
    action = action.replace(/[?&]$/, "");
    return await this.put<IFirstNameAndLastNameCommand>(action, body, this.Authorize(token));
  }

  async updateCustomerEmail(token: string, body: IEmailCommand): Promise<ICommandResult> {
    let action = "/api/v1/profile/update-email";
    action = action.replace(/[?&]$/, "");

    return await this.put<IEmailCommand>(action, body, this.Authorize(token));
  }
}