import { Box, TextField, Typography } from "@mui/material";
import React from "react";

interface Props {
  phoneNumber: string;
  code: (phoneNumber: string) => void;
}

export const OneTimePassword = ({ phoneNumber, code }: Props) => {
  const handleOTP = (event: any) => {
    code(event.target.value);
  };

  return (
    <>
      <Box padding="12px">
        <Typography
          variant="body1"
          marginBottom="4px"
          fontWeight="600"
          color="#"
        >
          Verification
        </Typography>
        <Typography variant="body2" color="#333333">
          Please enter the OTP we've sent to {phoneNumber} in order to update
          your phone number.
        </Typography>
      </Box>
      <Box padding="12px">
        <TextField
          fullWidth
          label="One Time Password"
          variant="outlined"
          size="small"
          required
          onChange={handleOTP}
        ></TextField>
      </Box>
      <Box padding="12px">
        <Typography variant="body2" color="#a19c9c">
          Didn't receive a code? Go back and try again.
        </Typography>
      </Box>
    </>
  );
};
