import { errorFromAuthenticationService, getAccessTokenFromAuthenticationService, getAuth0StateFromAuthenticationService, getIdTokenFromAuthenticationService, getRefreshTokenFromAuthenticationService, getTokenExpirationFromAuthenticationService, isAuthenticatedFromAuthenticationService, isLoadingFromAuthenticationService, loginWithPopupFromAuthenticationService, loginWithRedirectFromAuthenticationService, logoutFromAuthenticationService, UserFromAuthenticationService } from "../services/authenticationService";
import { useGlobalStore } from "../store";

/**
 * AuthenticationServiceHook Custom Hook
 */
const authenticationServiceHook: any = () => {
    const user = UserFromAuthenticationService();
    const accessToken = getAccessTokenFromAuthenticationService();
    const idToken = getIdTokenFromAuthenticationService();
    const refreshtToken = getRefreshTokenFromAuthenticationService();
    const tokenExpiration = getTokenExpirationFromAuthenticationService();
    const auth0State = getAuth0StateFromAuthenticationService();
    const error = errorFromAuthenticationService();
    const isAuthenticated = isAuthenticatedFromAuthenticationService();
    const isLoading = isLoadingFromAuthenticationService();
    const loginWithRedirect = loginWithRedirectFromAuthenticationService();
    const loginWithPopup = loginWithPopupFromAuthenticationService();
    const logout = logoutFromAuthenticationService();
    
    const authenticationServiceHookActions = {
        user,
        accessToken,
        idToken,
        refreshtToken,
        tokenExpiration,
        auth0State,
        error,
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        loginWithPopup,
        logout
    };

    return { ...authenticationServiceHookActions }
}

export default authenticationServiceHook;
