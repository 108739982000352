import { CommandResult } from "../../../domain/src/api/commandResult";
import { SetVerifyCodeState, VERIFY_CODE, VERIFY_CODE_FAIL } from "../../../domain/userInfo/verifyUpdatePhoneNumberCode";


export const initialState: SetVerifyCodeState = {
  result: new CommandResult({ isValid: false }),
};

export default function verifyCode(state = initialState, action: any) { 
  switch (action.type) {
    case VERIFY_CODE:
      return {
        ...state,
        result: action.payload
      };
    case VERIFY_CODE_FAIL:
      return { ...state, result: action.payload };
    default:
      return state;
  }
};
