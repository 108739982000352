import { Box, DialogActions, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { SecondaryButtonDesktop } from "../../../components/secondary-button/SecondaryButtonDesktop";
import { PrimaryButtonDesktop } from "../../../components/primary-button/PrimaryButtonDesktop";
import { NewEmail } from "./NewEmail";
import { ConfirmOptimaCredentials } from "./ConfirmOptimaCredentials";
import { useAuth0 } from "@auth0/auth0-react";
import { globalConfig } from "../../../configuration/config";
import { CorporateFare } from "@mui/icons-material";
import { EmailCommand } from "../../../commands/userInfo/email";
import { handleUpdateEmail } from "../../../actions/userInfo/updateEmail";
import useUpdateEmail from "../../../hooks/useUserInfo/useUpdateEmail";
import { LoadingButton } from "@mui/lab";
import { PrimaryLoadingButtonDesktop } from "../../../components/primary-loading-button/PrimaryLoadingButtonDesktop";

interface Props {
  onClose: () => void;
}

export const UpdateEmailSteps = ({ onClose }: Props) => {
  const { user, getIdTokenClaims, getAccessTokenSilently, logout } = useAuth0();
  const { updateEmail, handleUpdateEmail } = useUpdateEmail();

  const [isDisabled, setIsDisabled] = React.useState(false);
  const [saveEnabled, setSaveEnabled] = React.useState(false);
  const [isBusy, setIsBusy] = React.useState(false);

  const [newEmail, setNewEmail] = React.useState(user?.email);
  const [trustUsername, setTrustUsername] = React.useState("");
  const [trustPassword, setTrustPassword] = React.useState("");

  const [isAdfs, setIsAdfs] = React.useState(false);
  const [connection, setConnection] = React.useState("");

  const [renderItemIndex, setRenderItemIndex] = React.useState(3);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleNext = () => {
    setRenderItemIndex(1);
  };

  const handleBack = () => {
    setRenderItemIndex(0);
    setErrorMessage("");
  };

  const handleCancel = () => {
    setErrorMessage("");
    onClose();
  };

  const handleSave = () => {
    // do not change current state, but assure latest state is provided to variable
    let updatedNewEmail: string = "";
    setNewEmail((currentNewEmail) => {
      if (currentNewEmail === undefined) {
        updatedNewEmail = "";
      } else {
        updatedNewEmail = currentNewEmail;
      }
      return currentNewEmail;
    });

    let updatedTrustUsername: string = "";
    setTrustUsername((currentTrustUsername) => {
      updatedTrustUsername = currentTrustUsername;
      return currentTrustUsername;
    });

    let updatedTrustPassword: string = "";
    setTrustPassword((currentTrustPassword) => {
      updatedTrustPassword = currentTrustPassword;
      return currentTrustPassword;
    });

    onUpdateEmail(
      updatedNewEmail,
      updatedTrustUsername,
      updatedTrustPassword
    ).then(() => {
      setIsBusy(true);
    });
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    localStorage.clear();
  };

  const onChangeSaveEnabledState = (newSaveEnabledState: boolean) => {
    setSaveEnabled(newSaveEnabledState);
  };

  const onNewEmailInput = (newEmail: string) => {
    setNewEmail(newEmail);
  };

  const onTrustUsernameInput = (usernameInput: string) => {
    setTrustUsername(usernameInput);
  };

  const onTrustPasswordInput = (passwordInput: string) => {
    setTrustPassword(passwordInput);
  };

  const onUpdateEmail: any = useCallback(
    async (email: string, username?: string, password?: string) => {
      var accessToken = await getAccessTokenSilently({
        scopesToInclude: globalConfig.config.Scope || "email"
      });

      await handleUpdateEmail(
        accessToken,
        new EmailCommand({
          email: email,
          optimaUsername: username,
          optimaPassword: password,
        })
      );
    },
    []
  );

  const list = [
    {
      id: 0,
      Component: NewEmail,
      primaryButton: {
        title: "Next",
        action: handleNext,
      },
      secondaryButton: {
        title: "Cancel",
        action: handleCancel,
      },
    },

    {
      id: 1,
      Component: ConfirmOptimaCredentials,
      primaryButton: {
        title: "Save",
        action: handleSave,
      },

      secondaryButton: {
        title: "Back",
        action: handleBack,
      },
    },

    {
      id: 3, // distanced by one to discontinue logical progression of previous button sets
      Component: NewEmail,
      primaryButton: {
        title: "Save",
        action: handleSave,
      },

      secondaryButton: {
        title: "Cancel",
        action: handleCancel,
      },
    },
  ];

  const RenderItem = React.useMemo(
    () => list.find((i) => i.id === renderItemIndex),
    [renderItemIndex]
  );

  useEffect(() => {
    let isMounted = true;

    (async function GetTokenClaims() {
      var claims = await getIdTokenClaims();

      if (claims === undefined) {
        var token = await getAccessTokenSilently({
          scopesToInclude: globalConfig.config.Scope || "healthsuite",
        });
        claims = await getIdTokenClaims();
      } else {
        const trustsClaimKey = globalConfig.config.TrustsClaimKey;

        if (claims.hasOwnProperty(trustsClaimKey)) {
          var trusts = claims[trustsClaimKey];

          if (trusts.length > 1) {
            setErrorMessage(
              "You cannot change your email since you are associated with more than one trust."
            );
            setIsDisabled(true);
          } else if (trusts.length === 1) {
            setRenderItemIndex(0);
            setErrorMessage("");
          }
        }
      }

      if (isMounted) {
        setConnection(claims?.sub.split("|")[0]);
      }
    })();

    if (connection === "adfs") {
      setIsAdfs(true);
      setIsDisabled(true);
    }

    return () => {
      isMounted = false;
    };
  }, [connection, getIdTokenClaims]);

  useEffect(() => {
    if (updateEmail === undefined) {
      return;
    }

    setIsBusy(false);

    if (updateEmail.result && updateEmail.result.isValid) {
      setErrorMessage("");
      handleLogout();
    } else {
      setErrorMessage(updateEmail.result.message);
    }
  }, [updateEmail]);

  return (
    <>
      {RenderItem && (
        <>
          <div>
            {isAdfs ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                padding="16px"
              >
                <CorporateFare
                  sx={{ color: "#777777", marginRight: "12px" }}
                ></CorporateFare>
                <Typography
                  variant="body1"
                  fontFamily="inter"
                  marginTop="4px"
                  color="#555"
                >
                  Information managed by your organization.
                </Typography>
              </Box>
            ) : null}
          </div>

          <Box>
            <RenderItem.Component
              defaultEmail={user?.email}
              userEmail={newEmail}
              disabled={isDisabled}
              error={errorMessage}
              onChangeSaveEnabledState={onChangeSaveEnabledState}
              onNewEmailInput={onNewEmailInput}
              onOptimaUsernameInput={onTrustUsernameInput}
              onOptimaPasswordInput={onTrustPasswordInput}
            />
          </Box>

          <div className="DrawerFooter">
            <DialogActions>
              <SecondaryButtonDesktop
                buttonLabel={RenderItem.secondaryButton.title}
                onClick={RenderItem.secondaryButton.action}
              ></SecondaryButtonDesktop>
              <PrimaryLoadingButtonDesktop
                primaryButtonLabel={RenderItem.primaryButton.title}
                onClick={RenderItem.primaryButton.action}
                disabled={isDisabled || !saveEnabled}
                loading={isBusy}
              ></PrimaryLoadingButtonDesktop>
            </DialogActions>
          </div>
        </>
      )}
    </>
  );
};
