import styled from "styled-components";
import Button from "@mui/material/Button";
import React, { FC } from "react";

const StyledSecondaryButton = styled.button`
  display: flex;
  background-color:#000000
  justify-content: center;
  height: auto;
  border:none;
  margin-top:24px;
  width: 100%;
  @media (max-width: 478px) {
    & > .MuiButtonBase-root {
      height: 44px;
      width: 100%;
      font-size: 16px;
    }
  }
`;

interface Props {
  children?: any;
  buttonLabel?: any;
  onClick?: () => void;
}

export const SecondaryButtonDesktop: FC<Props> = ({
  children,
  buttonLabel,
  onClick,
}) => {
  return (
    <StyledSecondaryButton>
      <Button
        style={{
          backgroundColor: "#5F5C87",
        }}
        variant="contained"
        size="medium"
        disableElevation
        fullWidth
        onClick={onClick}
      >
        {buttonLabel}
      </Button>
    </StyledSecondaryButton>
  );
};
