import React, { useState } from "react";

export interface IProviderProps {
  children?: any;
}

export type QueryContextState = {
  token: string;
  auth0state: string;
  phoneNumber: string;
};

export const queryCtxDefaultValue = {
  state: { token: "", auth0state: "", phoneNumber: "" },
  setState: (state: QueryContextState) => {}, // noop default callback
};

export const QueryContext = React.createContext(queryCtxDefaultValue);

export const QueryProvider = (props: IProviderProps) => {
  const [state, setState] = useState(queryCtxDefaultValue.state);

  return (
    <QueryContext.Provider value={{ state, setState }}>
      {props.children}
    </QueryContext.Provider>
  );
};
