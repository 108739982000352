export class FirstNameAndLastNameCommand implements IFirstNameAndLastNameCommand {
    firstName?: string | undefined;
    lastName?: string | undefined;

    constructor(data?: IFirstNameAndLastNameCommand) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (this as any)[property] = (data as any)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.firstName = _data["firstName"];
            this.lastName = _data["lastName"];
        }
    }

    static fromJS(data: any): FirstNameAndLastNameCommand {
        data = typeof data === 'object' ? data : {};
        let result = new FirstNameAndLastNameCommand();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["firstName"] =  this.firstName;
        data["lastName"] = this.lastName;
        return data; 
    }
}

export interface IFirstNameAndLastNameCommand {
    firstName?: string | undefined;
    lastName?: string | undefined;
}
