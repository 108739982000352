import { CommandResult } from "../../../domain/src/api/commandResult";
import { UpdateEmailState, UPDATE_EMAIL, UPDATE_EMAIL_FAIL } from "../../../domain/userInfo/updateEmail";


export const initialState: UpdateEmailState = {
  result: new CommandResult({ isValid: false }),
};

export default function updateEmail(state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_EMAIL:
      return {
        ...state,
        result: action.payload,
      };
      case UPDATE_EMAIL_FAIL:
        return { ...state, result: action.payload };
    default:
      return state;
  }
};