import { CancelToken } from "axios";
import { IPasswordlessSMSFlowCommand } from "../domain/src/api/command";
import { ICommandResult } from "../domain/src/api/commandResult";
import { axiosClient } from "../api/axiosClient"; 

export class passwordlessClient extends axiosClient {  

    async startPasswordlessFlow(token: string, body: IPasswordlessSMSFlowCommand): Promise<ICommandResult> {
    const action = "/api/v1/profile/start-update-phone-number";
        
        return await this.post<IPasswordlessSMSFlowCommand>(action, body,this.Authorize(token));
    }

    async verifySmsCode(token: string, code: string | null | undefined, phoneNumber: string | null | undefined, cancelToken?: CancelToken | undefined): Promise<ICommandResult> {
        let action = "/api/v1/profile/verify-and-update-phone-number?";

        if (code !== undefined && code !== null)
            action += "Code=" + encodeURIComponent("" + code) + "&";

        if (phoneNumber !== undefined && phoneNumber !== null)
            action += "PhoneNumber=" + encodeURIComponent("" + phoneNumber) + "&";

        action = action.replace(/[?&]$/, "");
       
        return await this.post(action, null, this.Authorize(token));
    }      
} 