import { ICommandResult } from "../../domain/src/api/commandResult";

export const UPDATE_FIRST_NAME_AND_LAST_NAME = "UPDATE_FIRST_NAME_AND_LAST_NAME";
export const UPDATE_FIRST_NAME_AND_LAST_NAME_FAIL = "UPDATE_FIRST_NAME_AND_LAST_NAME_FAIL";

export interface UpdateFirstNameAndLastNameState {
  result: ICommandResult;
}

export interface UpdateFirstNameAndLastNameType {
  type: typeof UPDATE_FIRST_NAME_AND_LAST_NAME | typeof UPDATE_FIRST_NAME_AND_LAST_NAME_FAIL;
  payload: ICommandResult;
}
export type FirstNameAndLastNameType = UpdateFirstNameAndLastNameType;