export class PasswordlessSMSFlowCommand implements IPasswordlessSMSFlowCommand {
    phoneNumber?: string | undefined;

    constructor(data?: IPasswordlessSMSFlowCommand) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (this as any)[property] = (data as any)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.phoneNumber = _data["phoneNumber"];
        }
    }

    static fromJS(data: any): PasswordlessSMSFlowCommand {
        data = typeof data === 'object' ? data : {};
        let result = new PasswordlessSMSFlowCommand();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["phoneNumber"] = this.phoneNumber;
        return data; 
    }
}

export interface IPasswordlessSMSFlowCommand {
    phoneNumber?: string | undefined;
}
