import {
	VERIFY_CODE,
	VerifyCodeType,
	VERIFY_CODE_FAIL,
  } from "../../../domain/userInfo/verifyUpdatePhoneNumberCode/index";
import { passwordlessClient } from "../../../api/updatePhoneNumberClient";
import { ICommandResult } from "../../../domain/src/api/commandResult";
 
  // in actions folder
  export const verifyCodeAction = (result: ICommandResult): VerifyCodeType => {
	return {
	  type: VERIFY_CODE,
	  payload: result,
	};
  };
   
  export const verifyCodeFailAction = (err: ICommandResult): VerifyCodeType  => {
	return {
	  type: VERIFY_CODE_FAIL,
	  payload: err,
	};
  };

export const handleVerifyCode = (token: string, code: string, phoneNumber: string) => {
  return function (dispatch:any) {
    const endpoint = new passwordlessClient();
    endpoint
      .verifySmsCode(token, code, phoneNumber)
      .then((data) => {
        dispatch(verifyCodeAction(data));
        return data;
      })
      .catch((err) => {        
        dispatch(verifyCodeFailAction(err));
      });
  };
};
