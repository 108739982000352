import { MainSection } from "../../components/main-section/MainSection";
import React from "react";
import { Edit, PhoneTwoTone } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import { ScreenLayout } from "../../components/screen-layout/ScreenLayout";
import InputField from "../general-information/GeneralInformationPage";
import { Button } from "@mui/material";
import { GenericDrawer } from "../../components/generic-components/GenericDrawer";
import { NewPhoneNumber } from "./steps/NewPhoneNumber";
import authenticationServiceHook from "../../hooks/authenticationServiceHook";

export const PhoneNumberPage = () => {
  const { user } = authenticationServiceHook();

  const storedPhoneNumber = localStorage.getItem("phoneNumber")
    ? localStorage.getItem("phoneNumber")
    : user?.phone_number;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ScreenLayout>
        <MainSection
          PageIcon={PhoneTwoTone}
          pageTitle="Phone Number"
          pageDescription="Update your phone number"
        >
          <InputField>
            <TextField
              required
              id="phone-number"
              label="Phone Number"
              variant="standard"
              size="small"
              value={storedPhoneNumber ? storedPhoneNumber : ""}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
            />
          </InputField>

          <div className="IconButton">
            <Button
              color="primary"
              variant="contained"
              fullWidth
              startIcon={<Edit />}
              onClick={() => setOpen(true)}
            >
              Update
            </Button>
          </div>
        </MainSection>
        <GenericDrawer
          open={open}
          onClose={handleClose}
          drawerName="Update Phone Number"
          onSave={handleClose}
        >
          <NewPhoneNumber onClose={handleClose}></NewPhoneNumber>
        </GenericDrawer>
      </ScreenLayout>
    </>
  );
};
