import styled from "styled-components";
import Button from "@mui/material/Button";
import React, { FC } from "react";
import { LoadingButton } from "@mui/lab";

const StyledPrimaryLoadingButton = styled.button`
  display: flex;
  justify-content: center;
  height: auto;
  margin-top: 24px;
  width: 100%;
  border: none;
  @media (max-width: 478px) {
    & > .MuiButtonBase-root {
      height: 44px;
      width: 100%;
      font-size: 16px;
    }
  }
`;

interface Props {
  children?: any;
  primaryButtonLabel?: any;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

export const PrimaryLoadingButtonDesktop: FC<Props> = ({
  children,
  primaryButtonLabel,
  onClick,
  disabled,
  loading
}) => {
  return (
    <StyledPrimaryLoadingButton>
      <LoadingButton
        onClick={onClick}
        variant="contained"
        size="medium"
        disableElevation
        fullWidth
        disabled={disabled}
        loading={loading}
      >
        <span>{primaryButtonLabel}</span>
      </LoadingButton>
    </StyledPrimaryLoadingButton>
  );
};
